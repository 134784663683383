import './box.js';
import './circle.js';
import './cone.js';
import './cylinder.js';
import './dodecahedron.js';
import './icosahedron.js';
import './octahedron.js';
import './plane.js';
import './ring.js';
import './sphere.js';
import './tetrahedron.js';
import './torus.js';
import './torusKnot.js';
import './triangle.js';
